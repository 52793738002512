export const files = {
	'en-GB': require('./en_GB.json'),
	'en-NZ': require('./en_GB.json'),
	'nb-NO': require('./nb_NO.json'),
	'nl-NL': require('./nl_NL.json'),
	'sv-SE': require('./sv_SE.json'),
	'pt-PT': require('./pt_PT.json'),
	'da_DK': require('./da_DK.json'),
	'fi_FI': require('./fi_FI.json'),
	'gb': require('./en_GB.json'),
	'nb': require('./nb_NO.json'),
	'nl': require('./nl_NL.json'),
	'sv': require('./sv_SE.json'),
	'pt': require('./pt_PT.json'),
	'dk': require('./da_DK.json'),
	'fi': require('./fi_FI.json'),
};
