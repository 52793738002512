const isDevelopment = process.env.CONFIG_DEV;

const configDevelopment = {
	BitmovinPlayerLicenseKey: '5ee22a9e-158f-449b-96c7-3ad5144b2cf9',
	bitmovinAnalyticsLicenseKey: '689e337f-d68e-4afc-a2ba-3e7f260a5615',
	fairplayCertificateUrl:
		'https://cdn.withlove.tv/license/fairplay/fairplay.cer',
	userServiceUrl: 'https://dc-lov-ms-user.axprod.net',
	subscriptionServiceUrl: 'https://dc-lov-ms-subscription.axprod.net',
	catalogServiceUrl: 'https://dc-lov-cdn-catalog.withlove.tv',
	mediaUrl: 'https://dc-lov-cdn-media.withlove.tv',
	resourceUrl: 'https://dc-lov-cdn-resources.withlove.tv',
	searchUrl: 'https://search.withlove.tv/es/lov-movie,lov-series',
	webappCdnUrl: 'https://cdn.withlove.tv',
	facebookApiUrl: 'https://graph.facebook.com',
	facebookAppId: '265272347469966',
	facebookClientToken: '73dee5b83f3818ce3aad1cef4ace4fee',
	hermesApiUrl: 'https://api-tst.withlove.tv',
	sentryDNS: undefined,
	collections: {
		home: '0-8-home-',
		menu: '0-8-lov-',
		noResults: '0-8-no-search-results-',
	},
	appId: 'tv.withlove.app',
	appName: 'lov',
	platformName: 'WithLove',
	dimensions: {
		logo: {
			width: 120,
			height: (150 / 870) * 120,
		},
	},
	hygraph: {
		hygraphUrl:
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clf0vb2mr2bvb01um6dtc799d/master',
		hygraphToken:
			'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODUxMDgwMTgsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xmMHZiMm1yMmJ2YjAxdW02ZHRjNzk5ZC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjZmZGEyMTgtMGE1Yi00ZTQ2LTljY2UtZGM3NzM1NTJiMTQ0IiwianRpIjoiY2xpNGx0MHhmMmVsNjAxdDc2MDM2MmRxNCJ9.US3Ecr2Sai-ES2iJablNXIhrExDkR0rd8WI2MkPaY2EJMuT6nCr_emaFfmKHEoQRezqMorVbhh9zkuObkV_aC8tD9ibiE13Wr6pjSTqJeXZ-zXzVcASfVUyPFq4iJeZoALA0Q46J3zGsy1l9WVwJhmg4eHAzifzS77y5HMtdPwFgUIzXjx9XYfGRv2v-TQU7objTdY4UrB6lynW_bE3Mp0eETjpLoMKqTkKDJc--REFNKDGqoPnecTxjlzo1RVTmGET0RWT9NwsouaR9XvT5rLF6AJ0WN5kA3utkyj5gtSoVFSlGnsa1wjEocEMgXcIldqnEgMM5k5W_zri_5OVotfFQalsNxcvl_LaMQueJJY1a4xTUpSnt-mzujstVrCbbrkNEmwvpTQARqH-boLC92deiSqphZ4pFJohy5kcLXhlkK117GvUty3cOfY15tAVKkfJSRA-KRSzinviraiDnG90mXGIL77PqgJnr-qnWvniqWcQ08UBHTsIfvenLImEcc-i1ghTDOPHdVuLcvQBG74iTuwd9CvVPIq1oYvjJD7XiSRu2KihTjfvYBvpA5yqhr6tNVrHEXYcxLgDM3SKzRhNdWPyYgGBjpj_f0P7zn36rlHikEc5gBNbY7d0_Y1-v_lP2OuqodjjSGtNatKYM69qAdYsQXq9qmmPSOExsuEw',
		genreId: 'clk8c5jzj0v2w0auw2o81cm18',
		registrationBackground: 'ctv-register-background-lov',
		availableLocales: ['en', 'nl', 'nb', 'sv'],
	},
	search: {
		assetKeys: {
			movie: 'lov-movie',
			series: 'lov-series',
		},
	},
	localisation: {
		default: {
			language: 'en',
			countryCode: 'GB',
			languageTag: 'en-GB',
		},
		languageMap: {
			nl: 'Nederlands',
			en: 'English',
			sv: 'Svenska',
			nb: 'Norsk bokmål',
		},
	},
	cdnType: 'akamai',
	trackingUrl: 'https://dctracking-dev.dutchchannels.com',
	analyticsChannel: 'A495E56A013958F39771CEC7690DF76E',
	qrCodePage: 'https://withlove.tv/tv',
	platformUrl: 'https://withlove.tv/',
	mux: {
		env: 'tj1eikr0vcivc8ur07ep74r6d',
	},
	dev: {
		email: process.env.USER_EMAIL_LOV_DEV,
		password: process.env.USER_PASSWORD_LOV_DEV,
	},
};

const configProduction = {
	BitmovinPlayerLicenseKey: '5ee22a9e-158f-449b-96c7-3ad5144b2cf9',
	bitmovinAnalyticsLicenseKey: '689e337f-d68e-4afc-a2ba-3e7f260a5615',
	fairplayCertificateUrl:
		'https://cdn.withlove.tv/license/fairplay/fairplay.cer',
	userServiceUrl: 'https://dc-lov-ms-user.axprod.net',
	subscriptionServiceUrl: 'https://dc-lov-ms-subscription.axprod.net',
	catalogServiceUrl: 'https://dc-lov-cdn-catalog.withlove.tv',
	mediaUrl: 'https://dc-lov-cdn-media.withlove.tv',
	resourceUrl: 'https://dc-lov-cdn-resources.withlove.tv',
	searchUrl: 'https://search.withlove.tv/es/lov-movie,lov-series',
	webappCdnUrl: 'https://cdn.withlove.tv',
	facebookApiUrl: 'https://graph.facebook.com',
	facebookAppId: '265272347469966',
	facebookClientToken: '73dee5b83f3818ce3aad1cef4ace4fee',
	hermesApiUrl: 'https://api.withlove.tv',
	sentryDNS: !isDevelopment
		? 'https://b943f52f325942b5bfb5522b6f047e2f@o201346.ingest.sentry.io/6398984'
		: undefined,
	collections: {
		home: '0-8-home-',
		menu: '0-8-lov-',
		noResults: '0-8-no-search-results-',
	},
	appId: 'nl.withlove.app',
	appName: 'lov',
	platformName: 'WithLove',
	dimensions: {
		logo: {
			width: 120,
			height: (150 / 870) * 120,
		},
	},
	hygraph: {
		hygraphUrl:
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clf0vb2mr2bvb01um6dtc799d/master',
		hygraphToken:
			'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODUxMDgwMTgsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xmMHZiMm1yMmJ2YjAxdW02ZHRjNzk5ZC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjZmZGEyMTgtMGE1Yi00ZTQ2LTljY2UtZGM3NzM1NTJiMTQ0IiwianRpIjoiY2xpNGx0MHhmMmVsNjAxdDc2MDM2MmRxNCJ9.US3Ecr2Sai-ES2iJablNXIhrExDkR0rd8WI2MkPaY2EJMuT6nCr_emaFfmKHEoQRezqMorVbhh9zkuObkV_aC8tD9ibiE13Wr6pjSTqJeXZ-zXzVcASfVUyPFq4iJeZoALA0Q46J3zGsy1l9WVwJhmg4eHAzifzS77y5HMtdPwFgUIzXjx9XYfGRv2v-TQU7objTdY4UrB6lynW_bE3Mp0eETjpLoMKqTkKDJc--REFNKDGqoPnecTxjlzo1RVTmGET0RWT9NwsouaR9XvT5rLF6AJ0WN5kA3utkyj5gtSoVFSlGnsa1wjEocEMgXcIldqnEgMM5k5W_zri_5OVotfFQalsNxcvl_LaMQueJJY1a4xTUpSnt-mzujstVrCbbrkNEmwvpTQARqH-boLC92deiSqphZ4pFJohy5kcLXhlkK117GvUty3cOfY15tAVKkfJSRA-KRSzinviraiDnG90mXGIL77PqgJnr-qnWvniqWcQ08UBHTsIfvenLImEcc-i1ghTDOPHdVuLcvQBG74iTuwd9CvVPIq1oYvjJD7XiSRu2KihTjfvYBvpA5yqhr6tNVrHEXYcxLgDM3SKzRhNdWPyYgGBjpj_f0P7zn36rlHikEc5gBNbY7d0_Y1-v_lP2OuqodjjSGtNatKYM69qAdYsQXq9qmmPSOExsuEw',
		genreId: 'clk8c5jzj0v2w0auw2o81cm18',
		registrationBackground: 'ctv-register-background-lov',
		availableLocales: ['en', 'nl', 'nb', 'sv'],
	},
	search: {
		assetKeys: {
			movie: 'lov-movie',
			series: 'lov-series',
		},
	},
	localisation: {
		default: {
			language: 'en',
			countryCode: 'GB',
			languageTag: 'en-GB',
		},
		languageMap: {
			nl: 'Nederlands',
			en: 'English',
			sv: 'Svenska',
			nb: 'Norsk bokmål',
		},
	},
	cdnType: 'akamai',
	trackingUrl: 'https://dctracking.dutchchannels.com',
	analyticsChannel: 'A495E56A013958F39771CEC7690DF76E',
	qrCodePage: 'https://withlove.tv/tv',
	platformUrl: 'https://withlove.tv/',
	mux: {
		env: 'lhghf1nmhhbd8le94lg1bq3l2',
	},
	dev: {
		email: process.env.USER_EMAIL_LOV_PRD,
		password: process.env.USER_PASSWORD_LOV_PRD,
	},
};

const config = isDevelopment
	? {
			...configProduction,
			...configDevelopment,
	  }
	: configProduction;

export default config;
